<template>
  <div class="page-add-invoice">
    <div class="container">
      <nav class="breadcrumb">
        <ul class="breadcrumb-list">
          <li><router-link to="/dashboard" class="breadcrumb-link">Dashboard</router-link></li>
          <li><router-link to="/dashboard/invoices" class="breadcrumb-link">Invoices</router-link></li>
          <li class="breadcrumb-link is-active"><router-link to="/dashboard/invoices/add">Add</router-link></li>
        </ul>
      </nav>
      <div class="columns">
        <div class="column">
          <h1 class="title">Add invoice</h1>
        </div>
        <div class="column">
          <h2 class="title">Client</h2>
          <div class="select">
            <div class="field">
              <select class="field-input" name="client" v-model="invoice.client">
                <option value="" selected>==- Select client ---</option>
                <option
                  v-for="client in clients"
                  v-bind:key="client.id"
                  v-bind:value="client"
                >
                  {{ client.name }}
                </option>
            </select>
            </div>
          </div>
          <div class="box" v-if="invoice.client">
            <p><strong>{{ invoice.client.name }}</strong></p>
            <p><strong>Email:</strong> {{ invoice.client.email }}</p>
            <p v-if="invoice.client.address1">{{ invoice.client.address1 }}</p>
            <p v-if="invoice.client.address1">{{ invoice.client.address2 }}</p>
            <p v-if="invoice.client.address1 || invoice.client.place">{{ invoice.client.zipcode }} {{ invoice.client.place }}</p>
            <p v-if="invoice.client.country">{{ invoice.client.country }}</p>
          </div>
        </div>
        <div class="items-form">
          <h2 class="title">Items</h2>
          <ItemForm
            v-for="item in invoice.items"
            v-bind:key="item.item_num"
            v-bind:initialItem="item"
            v-on:updatePrice="updateTotals"
          >
          </ItemForm>
          <div class="buttons">
            <button class="button" @click="addItem">Add item</button>
          </div>
        </div>
        <div class="total-section">
          <div class="column">
            <h2 class="title">Misc</h2>
            <div class="field">
              <label class="field-label">Due days</label>
              <input type="number" class="field-input" v-model="invoice.due_days">
            </div>
            <div class="field">
              <label class="field-label">Sender reference</label>
              <input type="text" class="field-input" v-model="invoice.sender_reference">
            </div>
          </div>
          <div class="column">
            <h2 class="title">Total</h2>
            <p><strong>Net amount</strong>: {{ invoice.net_amount }}</p>
            <p><strong>Vat amount</strong>: {{ invoice.vat_amount }}</p>
            <p><strong>Gross amount</strong>: {{ invoice.gross_amount }}</p>
          </div>
        </div>
        <div class="buttons">
          <button class="button" @click="submitForm">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import ItemForm from '@/components/ItemForm.vue'
export default {
  name: 'AddInvoice',
  components: {
    ItemForm
  },
  data() {
    return {
      invoice: {
        client: '',
        items: [
          {
            item_num: 0,
            title: '',
            unit_price: '',
            quantity: 1,
            vat_rate: 0,
            net_amount: 0
          }
        ],
        due_days: 14,
        net_amount: 0,
        vat_amount: 0,
        gross_amount: 0
      },
      clients: []
    }
  },
  async mounted() {
    await this.getClients()
  },
  methods: {
    getClients() {
      axios
        .get('/api/v1/clients/')
        .then(response => {
          this.clients = response.data
        })
        .catch(error => {
          console.log(JSON.stringify(error))
        })
    },
    addItem() {
      this.invoice.items.push({
        item_num: this.invoice.items.length,
        title: '',
        unit_price: '',
        quantity: 1,
        vat_rate: 0,
        net_amount: 0
      })
    },
    updateTotals(changedItem) {
      let net_amount = 0
      let vat_amount = 0
      let item = this.invoice.items.filter(i => i.item_num === changedItem.item_num)
      item = changedItem
      for (let i = 0; i < this.invoice.items.length; i++) {
        const vat_rate = this.invoice.items[i].vat_rate
        vat_amount += this.invoice.items[i].net_amount * (vat_rate / 100)
        net_amount += this.invoice.items[i].net_amount
      }
      this.invoice.net_amount = parseFloat(net_amount.toFixed(2))
      this.invoice.vat_amount = parseFloat(vat_amount.toFixed(2));
      this.invoice.gross_amount = parseFloat((net_amount + vat_amount).toFixed(2));
      this.invoice.discount_amount = 0
    },
    submitForm() {
      this.invoice.client_name = this.invoice.client.name
      this.invoice.client_email = this.invoice.client.email
      this.invoice.client_org_number = this.invoice.client.org_number
      this.invoice.client_address1 = this.invoice.client.address1
      this.invoice.client_address2 = this.invoice.client.address2
      this.invoice.client_zipcode = this.invoice.client.zipcode
      this.invoice.client_place = this.invoice.client.place
      this.invoice.client_country = this.invoice.client.country
      this.invoice.client_contact_person = this.invoice.client.contact_person
      this.invoice.client_contact_reference = this.invoice.client.contact_reference
      this.invoice.client = this.invoice.client.id

      axios
        .post('/api/v1/invoices/', this.invoice)
        .then(response => {
          this.$router.push('/dashboard/invoices')
        })
        .catch(error => {
          console.log(JSON.stringify(error))
        })
    }
  }

}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/fomrs.scss";

.select, .select select {
  width: 100%;
}

.page-add-invoice {
  .container {
    .columns {
      .column {
        .title {
          padding: 10px 0;
        }
        .select,
        .select select {
          width: 100%;
        }
      }
      .items-form {
        padding: 20px 0 0 0;
        .button {
          background-color: #4CAF50;
          color: #fff;
          font-weight: 600;
        }
      }
      .total-section {
        display: flex;
        gap: 50px;
        @media(max-width: 500px) {
          flex-direction: column;
          gap: 10px;
        }
        .column {
          width: 50%;
          @media(max-width: 500px) {
            width: 100%;
          }
        }
      }
      .buttons {
        padding: 20px 0;
        .button {
          background-color: #4CAF50;
          font-weight: 600;
          color: #fff;
        }
      }
    }
  }
}


</style>